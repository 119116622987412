import { Autocomplete, Grid, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Chip from './extended/Chip'
import { useTheme } from '@mui/material/styles'

const CustomSelect = ({
  onChange,
  options,
  value,
  label,
  helperText,
  error,
  hasPrice
}) => {
  const [selectitems, setselectitems] = useState(options)
  const [groupOptions, setGroupOptions] = useState(false)
  const [valueIn, setValueIn] = useState(value ?? [])
  const [selected, setSelected] = useState(null)
  const theme = useTheme()
  useEffect(() => {
    setselectitems(options)
    setGroupOptions(options.some(opt => opt.group))
  }, [options])

  useEffect(() => {
    setValueIn(value)
  }, [value])

  return (
    <>
      {/* <Typography>Resources</Typography> */}

      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          select
          value={selected}
          label={label}
          onChange={e => {
            const val = e.target.value
            if (!val || val == '') {
              return
            }
            // remove from list
            setselectitems(prev => prev.filter(res => res.id != val.id))
            // add to a new list
            const newVal = [...value]

            newVal.push(val)
            setValueIn(newVal)
            onChange(newVal)
            setSelected('')
          }}
          error={error}
          helperText={helperText}
        >
          {selectitems.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item.name}{' '}
              {hasPrice ? (
                <>
                  <br /> Price {item.price}
                </>
              ) : (
                ''
              )}
            </MenuItem>
          ))}
        </TextField>

        {/* <Autocomplete
          id={'grouped-' + label}
          options={selectitems
            .map(res => {
              const newres = res
              if (!newres?.group && groupOptions) {
                newres.group = 'Other ' + label.toLowerCase()
              }
              return newres
            })
            .sort((a, b) => -b.group?.localeCompare(a?.group))}
          groupBy={option => option.group}
          getOptionLabel={option => option.name}
          onChange={(e, val) => {
            if (!val || val == '') {
              return
            }
            // remove from list
            setselectitems(prev => prev.filter(res => res.id != val.id))
            // add to a new list
            const newVal = [...value]

            newVal.push(val)
            setValueIn(newVal)
            onChange(newVal)
            setSelected('')
          }}
          renderInput={params => (
            <TextField
              {...params}
              value={selected}
              label={label}
              error={error}
              helperText={helperText}
            />
          )}
        /> */}
      </Grid>
      <Grid item xs={12} md={12} marginTop='10px'>
        {valueIn.map(val => (
          <Chip
            style={{
              margin: '3px'
            }}
            label={val.name}
            onDelete={() => {
              const newSelect = [...selectitems]
              newSelect.push(val)
              setselectitems(newSelect)
              const newValuein = valueIn.filter(inn => inn.id != val.id)
              setValueIn(newValuein)
              onChange(newValuein)
            }}
          />
        ))}
      </Grid>
    </>
  )
}
export default CustomSelect
