import {
  GET_BOOKED_UNITS,
  GET_BOOKINGS,
  GET_BOOKING_DISCOUNTS,
  GET_BOOKING_RESOURCES,
  GET_BOOKING_SCHEME,
  GET_BOOKING_SERVICES,
  GET_BOOKING_SLOTS,
  GET_NOBOOKING_SLOTS,
  GET_RESOURCE_BOOKED_UNITS,
  UPDATE_BOOKINGS,
  UPDATE_BOOKING_DISCOUNTS,
  UPDATE_BOOKING_RESOURCES,
  UPDATE_BOOKING_RESOURCES_LAYOUT,
  UPDATE_BOOKING_SERVICES,
  UPDATE_BOOKING_SLOTS,
  UPDATE_NOBOOKING_SLOTS
} from 'store/actions'
import axios, { globalRetriesCount } from 'utils/axios'
import API from 'utils/constants/API'
import { debounceFunction } from './helperFns'
const cryptoJs = require('crypto-js')

const KEY = process.env.REACT_APP_ENCRYPTION_KEY

export const getBookings = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.GET_BOOKINGS, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })

      const bookings = data.data.data

      dispatch({
        type: GET_BOOKINGS,
        bookings,
        total: data?.data?.meta?.total
      })
    } catch (err) {}
  }
}

export const getBookingResources = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.GET_BOOKING_RESOURCES, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })
      if (data.code != 200) {
        throw 'Error'
      }

      const bookingResources = data.data

      dispatch({
        type: GET_BOOKING_RESOURCES,
        bookingResources
      })
    } catch (err) {
      throw err
    }
  }
}

export const getNoBookingSlots = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.GET_NOBOOKING_SLOTS, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })

      if (data.code != 200) {
        throw 'Error'
      }
      const noBookingSlots = data.data

      dispatch({
        type: GET_NOBOOKING_SLOTS,
        noBookingSlots
      })
    } catch (err) {
      throw err
    }
  }
}

export const updateBooking = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.UPDATE_BOOKINGS, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (data.code != 200) {
        throw data?.message
      }
      dispatch({
        type: UPDATE_BOOKINGS,
        payload
      })
    } catch (err) {
      throw err
    }
  }
}

export const updateNoBookingSlots = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.UPDATE_NOBOOKING_SLOTS, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (data.code != 200) {
        throw data?.message
      }
      dispatch({
        type: UPDATE_NOBOOKING_SLOTS,
        payload
      })
    } catch (err) {
      throw err
    }
  }
}

export const updateBookingResource = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.UPDATE_BOOKING_RESOURCES, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (data.code != 200) {
        throw data?.message
      }
      dispatch({
        type: UPDATE_BOOKING_RESOURCES,
        payload
      })
    } catch (err) {
      throw err
    }
  }
}

export const getBookingServices = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.GET_BOOKING_SERVICES, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })
      if (data.code != 200) {
        throw 'Error'
      }
      const bookingServices = data.data

      dispatch({
        type: GET_BOOKING_SERVICES,
        bookingServices
      })
    } catch (err) {
      throw err
    }
  }
}

export const updateBookingServices = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.UPDATE_BOOKING_SERVICES, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (data.code != 200) {
        throw data?.message
      }
      dispatch({
        type: UPDATE_BOOKING_SERVICES,
        payload
      })
    } catch (err) {
      throw err
    }
  }
}

export const getBookingSlots = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.GET_BOOKING_SLOTS, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })
      if (data.code != 200) {
        throw 'Error'
      }
      const bookingSlots = data.data
      dispatch({
        type: GET_BOOKING_SLOTS,
        bookingSlots
      })
    } catch (err) {
      throw err
    }
  }
}

export const updateBookingSlots = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.UPDATE_BOOKING_SLOTS, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (data.code != 200) {
        throw data?.message
      }
      dispatch({
        type: UPDATE_BOOKING_SLOTS,
        payload
      })
    } catch (err) {
      throw err
    }
  }
}

export const getBookingDiscounts = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.GET_BOOKING_DISCOUNTS, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })

      if (data.code != 200) {
        throw 'Error'
      }

      const bookingDiscounts = data.data

      dispatch({
        type: GET_BOOKING_DISCOUNTS,
        bookingDiscounts
      })
    } catch (err) {
      throw err
    }
  }
}

export const updateBookingDiscounts = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.UPDATE_BOOKING_DISCOUNTS, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (data.code != 200) {
        throw data?.message
      }
      dispatch({
        type: UPDATE_BOOKING_DISCOUNTS,
        payload
      })
    } catch (err) {
      throw err
    }
  }
}

export const getBookingScheme = payload => {
  const authenticated = localStorage.getItem('authenticated')

  return async dispatch => {
    try {
      /***to be replaced with uuid and type**/
      let localKey =
        'bookingScheme_' + payload?.listing_title + '_' + payload?.listing_type
      let localScheme = localStorage.getItem(localKey)

      if (localScheme) {
        const bytes = await cryptoJs.AES.decrypt(localScheme, KEY)
        const text = bytes.toString(cryptoJs.enc.Utf8)
        localScheme = JSON.parse(text)
        dispatch({
          type: GET_BOOKING_SCHEME,
          bookingScheme: localScheme,
          payload
        })
      }

      const response = await axios.post(API.READ_BOOKING_SCHEME, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })

      if (response.data.code == 200) {
        const bookingScheme = response.data.data
        let local = await cryptoJs.AES.encrypt(
          JSON.stringify(bookingScheme),
          KEY
        ).toString()
        localStorage.setItem(localKey, local)
        dispatch({
          type: GET_BOOKING_SCHEME,
          bookingScheme
        })
      } else {
        if (authenticated)
          debounceFunction(() => dispatch(getBookingScheme(payload)), 2000)
      }
    } catch (err) {
      if (authenticated)
        debounceFunction(() => dispatch(getBookingScheme(payload)), 2000)
    }
  }
}

export const getBookedUnits = payload => {
  return async dispatch => {
    try {
      const response = await axios.post(API.GET_BOOKED_UNITS, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })

      const bookedUnits = response?.data?.data
      if (response.data.code == 200)
        dispatch({
          type: GET_BOOKED_UNITS,
          bookedUnits
        })
    } catch (err) {}
  }
}

export const getResourceBookedUnits = async payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(
        API.GET_RESOURCES_BOOKED_UNITS,
        payload,
        {
          'axios-retry': {
            retries: globalRetriesCount
          }
        }
      )
      const resourceBookedUnits = data?.data
      if (data.code == 200) {
        dispatch({
          type: GET_RESOURCE_BOOKED_UNITS,
          resourceBookedUnits
        })
      }
    } catch (e) {}
  }
}

export const updateResourceLayout = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(
        API.UPDATE_BOOKING_RESOURCES_LAYOUT,
        payload
      )
      const response = data?.data
      if (data.code == 200) {
        dispatch({
          type: UPDATE_BOOKING_RESOURCES_LAYOUT,
          response
        })
      } else {
        throw 'Error'
      }
    } catch (e) {
      throw e
    }
  }
}

///helpers
export const capitalize = string => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}
