import {
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Divider,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Stack
} from '@mui/material'
import useAuth from 'hooks/useAuth'
import React, { useEffect, useState } from 'react'
import { capitalize } from 'store/actions/bookingActions'
import FormDialog from 'ui-component/modals/FormDialog'
import { useDispatch, useSelector } from 'react-redux'
import axios, { globalRetriesCount } from 'utils/axios'
import API from 'utils/constants/API'
import Loader from 'ui-component/Loader'
import { SNACKBAR_OPEN } from 'store/actions'
import BookingView from './BookingView'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
import AnimateButton from 'ui-component/extended/AnimateButton'

const editPermission = 'booking-scan'
const editPermissionVal = 'write'

const statuses = [
  // {
  //   title: 'Booked',
  //   status: 'booked'
  // },
  {
    title: 'Checked in',
    status: 'checked in'
  },
  // {
  //   title: 'In service',
  //   status: 'in service'
  // },
  {
    title: 'Checked out',
    status: 'checked out'
  }
  // {
  //   title: 'No show',
  //   status: 'no show'
  // },
  // {
  //   title: 'Canceled',
  //   status: 'canceled'
  // }
]

export const BookedUnitSearch = ({ open, handleClose, onEdit }) => {
  const [booking, setbooking] = useState({})
  const [loading, setloading] = useState(false)
  const [updatingStatus, setupdatingStatus] = useState(false)
  const [updatingNotStatus, setupdatingNotStatus] = useState(true)
  const [currentStatus, setcurrentStatus] = useState('checked in')
  const [ifNotStatus, setifNotStatus] = useState('checked out')
  const [ifNotEnded, setifNotEnded] = useState(false)
  const [ifNotTooEarly, setifNotTooEarly] = useState(false)

  const [bookedUnit, setbookedUnit] = useState({})
  const [summaryColumns, setSummaryColumns] = useState([])
  const [search, setSearch] = useState('')
  const { hasPermission } = useAuth()
  const theme = useTheme()

  const dispatch = useDispatch()

  const bookingSchemes = useSelector(
    ({ bookingStore }) => bookingStore.bookingSchemes
  )
  const listing = useSelector(
    ({ listingsStore }) => listingsStore.selectedListing
  )
  const clearSearch = () => {
    setbooking({})
    setbookedUnit({})
  }
  const clearState = () => {
    setSearch('')
    setloading(false)
    setupdatingStatus(false)
    clearSearch()
  }

  useEffect(() => {
    // const status = localStorage.getItem('unit_search_status')
    // setcurrentStatus('checked in')
  }, [])

  useEffect(() => {
    setSummaryColumns([
      {
        id: 'items',
        label: capitalize(bookingSchemes.booked_unit_si),
        minWidth: 5
      },
      {
        id: 'price',
        label: 'Amount',
        minWidth: 5
      }
    ])
  }, [bookingSchemes])

  useEffect(() => {
    localStorage.setItem('unit_search_status', currentStatus)
  }, [currentStatus])

  const updateStatus = async (booked_unit_uuid, status = null) => {
    setloading(true)
    clearSearch()
    try {
      const payload = {
        listing_id: listing?.id,
        listing_type: listing?.listing_type,
        status: status ?? currentStatus,
        if_not_status: updatingNotStatus ? ifNotStatus : '',
        if_not_ended: status ? false : ifNotEnded,
        if_not_too_early: status ? false : ifNotTooEarly,
        booked_unit_uuid
      }

      const { data } = await axios.post(API.BOOKED_UNIT_STATUS_UPDATE, payload)

      setloading(false)
      if (data.code == 200) {
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: 'Status updated successfully',
          variant: 'alert',
          alertSeverity: 'success'
        })
        getBooking(booked_unit_uuid)
      }
    } catch (err) {
      setSearch('')
      clearSearch()
      setloading(false)

      let msg = 'Something went wrong, please try again.'

      if (err?.response?.data?.message?.current_status_message) {
        msg = err?.response?.data?.message?.current_status_message
      }

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: msg,
        variant: 'alert',
        alertSeverity: 'warning'
      })
    }
  }

  const getBooking = async booked_unit_uuid => {
    setloading(false)
    clearSearch()
    try {
      const payload = {
        listing_id: listing?.id,
        listing_type: listing?.listing_type,
        booked_unit_uuid
      }

      const { data } = await axios.post(API.GET_BOOKING_FORM, payload)
      const booking = data?.data
      const unit = booking.booked_units?.find(
        unt => unt.booked_unit_uuid == booked_unit_uuid
      )

      if (data.code == 200) {
        setbooking(booking)
        setbookedUnit(unit ?? {})
        setSearch('')
      }

      setloading(false)
    } catch (err) {
      clearSearch()
      setSearch('')

      setloading(false)
      let msg = 'Something went wrong, please try again.'
      const messages = err?.response?.data?.message

      if (messages?.current_state_message) {
        msg = messages?.current_state_message
      }

      if (msg)
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: msg,
          variant: 'alert',
          alertSeverity: 'warning'
        })
    }
  }

  return (
    <FormDialog
      handleClose={() => {
        clearState()
        handleClose()
      }}
      open={open}
      title={'Scan booking'}
    >
      {loading && <Loader />}

      <Grid
        container
        // width={'100%'}
        justifyContent={'center'}
        sx={{ marginBottom: 3 }}
        spacing={2}
      >
        {hasPermission(editPermission, editPermissionVal) && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={updatingStatus}
                  onChange={e => {
                    if (!updatingStatus) {
                      setupdatingNotStatus(true)
                      setifNotEnded(true)
                      setifNotTooEarly(true)
                    }

                    setupdatingStatus(prev => !prev)
                  }}
                />
              }
              label={'Update status on scan'}
            />
          </Grid>
        )}

        <Grid item md={12}>
          <Grid container xs={12} spacing={2} sx={{ paddingLeft: 6 }}>
            {updatingStatus && (
              <>
                <Grid xs={12} md={6}>
                  <TextField
                    select
                    fullWidth
                    type='number'
                    label={'Update status to'}
                    value={currentStatus}
                    onChange={e => {
                      const val = e.target.value
                      setifNotEnded(val == 'checked in')
                      setifNotTooEarly(true)
                      setcurrentStatus(val)
                    }}
                  >
                    {statuses.map(itm => (
                      <MenuItem value={itm.status}>{itm.title}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid container xs={12} spacing={2}>
                  {onEdit && (
                    <>
                      <Grid item md={6} xs={12}>
                        <FormControlLabel
                          disabled={
                            !hasPermission(editPermission, editPermissionVal)
                          }
                          control={
                            <Checkbox
                              checked={ifNotTooEarly}
                              onChange={e => {
                                setifNotTooEarly(prev => !prev)
                              }}
                            />
                          }
                          label={'Only if not too early'}
                        />
                      </Grid>
                      {currentStatus == 'checked in' && (
                        <Grid item md={6} xs={12}>
                          <FormControlLabel
                            disabled={
                              !hasPermission(editPermission, editPermissionVal)
                            }
                            control={
                              <Checkbox
                                checked={ifNotEnded}
                                onChange={e => {
                                  setifNotEnded(prev => !prev)
                                }}
                              />
                            }
                            label={'Only if not ended'}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </>
            )}
          </Grid>

          {/* {updatingStatus && (
            <Grid container spacing={2} sx={{ paddingLeft: 4 }}>
              <Grid item md={6} xs={12}>
                <FormControlLabel
                  disabled={!hasPermission(editPermission, editPermissionVal)}
                  control={
                    <Checkbox
                      checked={updatingNotStatus}
                      onChange={e => {
                        setupdatingNotStatus(prev => !prev)
                      }}
                    />
                  }
                  label={'If not checked out'}
                />
                {updatingNotStatus && (
                  <Grid sx={{ paddingLeft: 8 }} xs={12} sm={10}>
                    <TextField
                      select
                      fullWidth
                      type='number'
                      label={'Only if not checked out'}
                      value={ifNotStatus}
                      onChange={e => {
                        const val = e.target.value
                        setifNotStatus(val)
                      }}
                    >
                      {statuses.map(itm => (
                        <MenuItem value={itm.status}>{itm.title}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControlLabel
                  disabled={!hasPermission(editPermission, editPermissionVal)}
                  control={
                    <Checkbox
                      checked={ifNotEnded}
                      onChange={e => {
                        setifNotEnded(prev => !prev)
                      }}
                    />
                  }
                  label={'If not ended'}
                />
              </Grid>
            </Grid>
          )} */}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={'Scan ' + bookingSchemes.booked_unit_si + ' QR code here'}
            placeholder={
              'Scan ' + bookingSchemes.booked_unit_si + ' QR code here'
            }
            id='search'
            name='search'
            value={search}
            onChange={e => {
              const val = e.target.value
              setSearch(val)
              if (val.length == 36) {
                updatingStatus ? updateStatus(val) : getBooking(val)
              }
            }}
          />
        </Grid>
      </Grid>
      {bookedUnit?.booked_unit_uuid && (
        <Grid textAlign={'center'}>
          <Grid
            md={'auto'}
            style={{
              padding: 16,
              maxWidth: 'contain',
              backgroundColor: 'rgba(128,128,128,.1)'
            }}
          >
            <div
              style={{
                marginTop: 5
              }}
            >
              <span
                style={{
                  color:
                    bookedUnit.status == 'canceled'
                      ? theme.palette.orange.dark
                      : bookedUnit.status == 'no show'
                      ? theme.palette.orange.dark
                      : bookedUnit.status == 'checked in'
                      ? theme.palette.success.dark
                      : bookedUnit.status == 'checked out'
                      ? theme.palette.grey[700]
                      : bookedUnit.status == 'in service'
                      ? theme.palette.success.dark
                      : theme.palette.mode == 'dark'
                      ? theme.palette.common.dark
                      : theme.palette.primary.dark
                }}
              >
                {capitalize(bookedUnit.status)}
              </span>
            </div>
            <div fontWeight={'bold'}>
              {moment(bookedUnit.booked_date).format('MMMM DD, YYYY')} at{' '}
              {moment(
                bookedUnit.booked_date + ' ' + bookedUnit.booked_time
              ).format('hh:mm A')}
              {/* <div fontWeight={'bold'}>
                (
                {humanizeDuration(bookedUnit.service_interval_time * 1000 * 60)}
                )
              </div> */}
            </div>
            <div>
              {bookingSchemes.sc_quantity && (
                <Typography>
                  {bookedUnit.quantity +
                    ' ' +
                    (bookedUnit.quantity > 1
                      ? bookingSchemes.quantity_pl
                      : bookingSchemes.quantity_si)}
                </Typography>
              )}
              <Typography fontWeight={'bold'}>
                {bookedUnit.service_name}
              </Typography>
              {/* {' - '}
              {'SAR ' + bookedUnit.price} */}
            </div>
            {bookedUnit.booking_options?.length > 0 && (
              <div>
                {/* Options:{' '} */}
                {bookedUnit.booking_options?.map((opt, index) => (
                  <span>
                    {opt.name} ({opt.quantity}){/* SAR {opt.price} */}
                    {index == bookedUnit.booking_options?.length - 1
                      ? ' '
                      : ', '}
                  </span>
                ))}
              </div>
            )}
            <div style={{ marginBottom: '10px' }}>
              <Typography fontWeight={'bold'}>
                {bookedUnit.resource_name}
              </Typography>
            </div>

            {/* {parseFloat(bookedUnit.discount_percent) > 0 && (
              <div style={{ color: theme.palette.error.light }}>
                {(parseFloat(bookedUnit.discount_percent) * 100).toFixed()}% OFF
              </div>
            )} */}
            {/* <div>
              Created on {moment(bookedUnit.created_at).format('MMMM DD, YYYY')}{' '}
              at {moment(bookedUnit.created_at).format('hh:mm A')}
            </div> */}
            {bookedUnit.created_at != bookedUnit.updated_at && (
              <div>Updated {moment(bookedUnit.utc_updated_at).fromNow()}</div>
            )}

            {onEdit &&
              hasPermission(editPermission, editPermissionVal) &&
              bookedUnit.status != 'canceled' &&
              bookedUnit.status != 'checked out' && (
                <>
                  {bookedUnit.status == 'checked in' ? (
                    <Grid item xs={12} pt={2}>
                      <Stack direction='row' justifyContent='center'>
                        <AnimateButton>
                          <Button
                            onClick={() => {
                              updateStatus(
                                bookedUnit?.booked_unit_uuid,
                                'checked out'
                              )
                            }}
                            variant='outlined'
                            type='submit'
                          >
                            Check out
                          </Button>
                        </AnimateButton>
                      </Stack>
                    </Grid>
                  ) : (
                    <Grid item xs={12} pt={2}>
                      <Stack direction='row' justifyContent='center'>
                        <AnimateButton>
                          <Button
                            onClick={() => {
                              updateStatus(
                                bookedUnit?.booked_unit_uuid,
                                'checked in'
                              )
                            }}
                            variant='contained'
                            type='submit'
                          >
                            Check in
                          </Button>
                        </AnimateButton>
                      </Stack>
                    </Grid>
                  )}
                </>
              )}
            {/* <div style={{ marginTop: 5 }}>{bookedUnit.booked_unit_uuid}</div> */}
          </Grid>
        </Grid>
      )}
      <BookingView
        onEdit={
          onEdit
            ? () => {
                const selectedBooking = booking
                const bookedUnits = selectedBooking.booked_units.map(u => {
                  return { ...u, old_ending_date_time: u.ending_date_time }
                })

                let phone = selectedBooking.phone
                phone = phone?.slice(
                  selectedBooking?.country_code?.length,
                  phone.length
                )

                selectedBooking.phone = phone

                onEdit(selectedBooking, bookedUnits)
                clearState()
              }
            : () => {}
        }
        canEdit={onEdit ? true : false}
        selectedUUID={bookedUnit.booked_unit_uuid}
        booking={booking}
        summaryColumns={summaryColumns}
      />
    </FormDialog>
  )
}
