import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, ButtonBase, Tooltip } from '@mui/material'

// project imports
import LogoSection from '../LogoSection'
import ListSelectSection from './ListSelectSection'
import LocalizationSection from './LocalizationSection'
import MobileSection from './MobileSection'
import ProfileSection from './ProfileSection'
import NotificationSection from './NotificationSection'

// assets
import { IconMenu2 } from '@tabler/icons'
import useAuth from 'hooks/useAuth'
import { QrCodeScanner } from '@mui/icons-material'
import { useState } from 'react'
import { BookedUnitSearch } from 'views/Bookings/BookedUnitSearch'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme()
  const { hasPermission } = useAuth()
  const [scan, setScan] = useState(false)
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box
          component='span'
          sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant='rounded'
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              '&:hover': {
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color='inherit'
          >
            <IconMenu2 stroke={1.5} size='1.3rem' />
          </Avatar>
        </ButtonBase>
      </Box>
      {/* header search */}
      <ListSelectSection />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      {/* live customization & localization */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <LocalizationSection />
      </Box> */}
      {(hasPermission('booking-scan', 'read') ||
        hasPermission('booking-scan', 'write')) && (
        <Tooltip title='Scan booking'>
          <QrCodeScanner
            sx={{
              mx: 3,
              [theme.breakpoints.down('md')]: {
                mx: 2
              },
              cursor: 'pointer'
            }}
            onClick={() => setScan(true)}
          />
        </Tooltip>
      )}
      {/* notification & profile */}
      {/* //check is MERCHANT or has PERMISSION */}
      {(hasPermission('notifications', 'read') ||
        hasPermission('notifications', 'write')) && <NotificationSection />}

      <ProfileSection />
      {/* mobile header */}
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box>
      <BookedUnitSearch open={scan} handleClose={() => setScan(false)} />
    </>
  )
}

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
}

export default Header
