import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import { WarningRounded } from '@mui/icons-material'

export const InfoDialog = props => {
  const theme = useTheme()
  return (
    <Dialog
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={props.open}
      onClose={props.onClose}
    >
      {/* <DialogTitle
        style={{ textAlign: 'center' }}
        id='confirmation-dialog-title'
      >
        {props.title}
      </DialogTitle> */}

      <DialogContent
        style={{
          textAlign: 'center'
          // paddingTop: 0,
          // paddingBottom: 0
        }}
      >
        <WarningRounded
          sx={{
            height: '30px',
            width: '30px',
            color: theme.palette.warning.main
          }}
          color={theme.palette.warning.main}
        />
        {props.children}
      </DialogContent>

      {/* <DialogActions sx={{ px: 2.5, pt: 2.5 }}>
        <Button
          // sx={{ color: theme.palette.error.dark }}
          variant='outlined'
          color='secondary'
          onClick={props.onClose}
        >
          {props.btnText ?? 'Okay'}
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}
