import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState
} from 'react'
import API from '../utils/constants/API'

// third-party
import { Chance } from 'chance'

// reducer - state management
import {
  ACCOUNT_FAILED,
  CLEAR_LISTINGS,
  LOGIN,
  LOGOUT,
  SNACKBAR_OPEN
} from 'store/actions'
import accountReducer from 'store/reducers/accountReducer'

// project imports
import Loader from 'ui-component/Loader'
import axios, { globalRetriesCount } from 'utils/axios'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const chance = new Chance()

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  accountFailed: false,
  user: null
}

const setSession = authenticated => {
  if (authenticated) {
    localStorage.setItem('authenticated', authenticated.toString())
    // axios.defaults.headers.common.Authorization = `Bearer ${authenticated}`
  } else {
    localStorage.removeItem('authenticated')
    // delete axios.defaults.headers.common.Authorization
  }
}

// ==============================|| Auth CONTEXT & PROVIDER ||============================== //
const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState)
  const [isAssociate, setIsAssociate] = useState(false)
  const theme = useTheme()

  const dispatchA = useDispatch()
  const updateStore = () => {
    dispatchA({ type: CLEAR_LISTINGS })
    dispatch({ type: LOGOUT })
  }
  const init = useCallback(async () => {
    try {
      const authenticated = localStorage.getItem('authenticated')

      if (authenticated) {
        setSession(authenticated)
        const response = await axios.post(
          API.ACCOUNT,
          {},
          {
            'axios-retry': {
              retries: globalRetriesCount
            }
          }
        )
        const { data: user } = response.data
        setIsAssociate(typeof user?.permissions == 'undefined' ? false : true)
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user
          }
        })
      } else {
        updateStore()
      }
    } catch (err) {
      dispatch({
        type: ACCOUNT_FAILED
      })
      // console.log(err.message == 'Network Error')
      // updateStore()
    }
  }, [])

  useEffect(() => {
    init()
  }, [])

  const hasPermission = (permission, value) => {
    const { user } = state
    if (!isAssociate) return true
    return (
      user?.permissions[permission] && user?.permissions[permission] == value
    )
  }
  const login = async (email, password, associate = false) => {
    const payload = {
      email,
      password,
      associate
    }

    if (!associate) delete payload.associate
    else {
      payload.associate = 'true'
    }

    const response = await axios.post(API.LOGIN, payload)
    if (response.data.code == 200) {
      const authenticated = 'true'
      setSession(authenticated)
      init()

      const phoneRegex = /android|iphone|kindle|silk|ipad/i
      const isMobile = phoneRegex.test(navigator.userAgent.toLowerCase())

      if (isMobile) {
        dispatchA({
          type: SNACKBAR_OPEN,
          open: true,
          message: 'Funtionality might be limited for this device.',
          variant: 'alert',
          alertSeverity: 'warning'
        })
      }
      // dispatch({
      //   type: LOGIN,
      //   payload: {
      //     isLoggedIn: true,
      //     user: { email }
      //   }
      // })
    }
    return response
  }

  const logout = async () => {
    try {
      const response = await axios.post(
        API.LOGOUT,
        {},
        {
          'axios-retry': {
            retries: globalRetriesCount
          }
        }
      )
      if (response.data.code == 200) {
        setSession(null)
        localStorage.removeItem('authenticated')

        updateStore()
        window.location.replace('/login')
      }
    } catch (err) {}
  }

  const updateProfile = () => {}

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />
  }

  if (state.accountFailed) {
    const mode = localStorage.getItem('mode')
    return (
      <Grid
        sx={{
          width: '100vw',
          height: '100vh',
          background:
            mode === 'dark'
              ? theme.palette.common.black
              : theme.palette.common.white
        }}
      ></Grid>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        init,
        updateProfile,
        isAssociate,
        hasPermission
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthContext
