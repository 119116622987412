import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Divider, List, Typography } from '@mui/material'

// project imports
import NavItem from '../NavItem'
import NavCollapse from '../NavCollapse'
import { debounceFunction, throttleFunction } from 'store/actions/helperFns'
import API from 'utils/constants/API'
import axios from 'utils/axios'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getChatCount } from 'store/actions/listingActions'
import { useLocation } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const renderMenuItem = menu => {
  switch (menu.type) {
    case 'collapse':
      return <NavCollapse key={menu.id} menu={menu} level={1} />
    case 'item':
      return <NavItem key={menu.id} item={menu} level={1} />
    default:
      return (
        <Typography key={menu.id} variant='h6' color='error' align='center'>
          Menu Items Error
        </Typography>
      )
  }
}

const NavGroup = ({ item }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { isAssociate, hasPermission, user } = useAuth()
  const location = useLocation()
  const { selectedListing, chatCount } = useSelector(
    ({ listingsStore }) => listingsStore
  )

  const reloadChatCount = async () => {
    if (!selectedListing) {
      return
    }
    const payload = {
      listing_id: selectedListing?.id,
      listing_type: selectedListing?.listing_type
    }
    dispatch(getChatCount(payload))
  }

  useEffect(() => {
    debounceFunction(() => {
      reloadChatCount()
    }, 100)
    const counter = setInterval(
      () => throttleFunction(() => reloadChatCount(), 100),
      location.pathname == '/chats' ? 1000 * 60 * 3 : 1000 * 60 * 5
    )
    return () => clearInterval(counter)
  }, [selectedListing?.title, selectedListing?.listing_type, location.pathname])

  // menu list collapse & items
  const items = item.children?.map(menu => {
    //check is MERCHANT or has PERMISSION
    if (user?.credit_balance != null && !isAssociate) {
      return renderMenuItem(menu)
    }

    if (
      menu.id == 'dashboard' &&
      (hasPermission('dashboard', 'read') ||
        hasPermission('dashboard', 'write'))
    ) {
      return renderMenuItem(menu)
    } else if (
      user?.credit_balance != null &&
      menu.id == 'bookings' &&
      (hasPermission('bookings', 'read') || hasPermission('bookings', 'write'))
    ) {
      return renderMenuItem(menu)
    } else if (
      user?.credit_balance != null &&
      menu.id == 'chat' &&
      (hasPermission('chat', 'read') || hasPermission('chat', 'write'))
    ) {
      return renderMenuItem(menu)
    } else if (
      user?.credit_balance != null &&
      menu.id == 'booking_setup' &&
      (hasPermission('booking-setup', 'read') ||
        hasPermission('booking-setup', 'write'))
    ) {
      return renderMenuItem(menu)
    } else if (
      menu.id == 'timeSlots' &&
      (hasPermission('timeslots', 'read') ||
        hasPermission('timeslots', 'write'))
    ) {
      return renderMenuItem(menu)
    } else if (
      menu.id == 'details' &&
      (hasPermission('details', 'read') || hasPermission('details', 'write'))
    ) {
      return renderMenuItem(menu)
    } else if (
      user?.credit_balance != null &&
      menu.id == 'bookingReports' &&
      (hasPermission('reports', 'read') || hasPermission('reports', 'write'))
    ) {
      return renderMenuItem(menu)
    } else if (
      user?.credit_balance != null &&
      menu.id == 'settings' &&
      (hasPermission('booking-settings', 'read') ||
        hasPermission('booking-settings', 'write') ||
        hasPermission('chat-settings', 'read') ||
        hasPermission('chat-settings', 'write'))
    ) {
      return renderMenuItem(menu)
    } else if (
      menu.id == 'submissions' &&
      hasPermission('photo-submissions', 'write')
    ) {
      return renderMenuItem(menu)
    } else {
      return
    }
  })

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography
              variant='caption'
              sx={{ ...theme.typography.menuCaption }}
              display='block'
              gutterBottom
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant='caption'
                  sx={{ ...theme.typography.subMenuCaption }}
                  display='block'
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  )
}

NavGroup.propTypes = {
  item: PropTypes.object
}

export default NavGroup
