import { useEffect, useState } from 'react'

// material-ui
import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

// assets
import DateRangeIcon from '@mui/icons-material/DateRange'
import moment from 'moment'

// ==============================|| CUSTOM DATETIME ||============================== //

const CustomDatePicker = props => {
  const [value, setValue] = useState(props.value ? new Date(props.value) : null)

  useEffect(() => {
    setValue(props.value ? new Date(props.value) : null)
  }, [props.value])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        slotProps={{
          textField: { fullWidth: true }
        }}
        label={props?.title}
        onChange={newValue => {
          setValue(newValue)
          props.onChange(moment(newValue).format('yyyy-MM-DD'))
        }}
        format='yyyy-MM-dd'
        onError={props.onError}
        minDate={new Date(props.minDate)}
        maxDate={props.maxDate ? new Date(props.maxDate) : undefined}
        // inputFormat={'yyyy-MM-dd'}
        // inputFormat='yyyy/MM/dd'
        // mask='___/__/__'
        // inputFormat={props?.mode == 'time' ? 'hh:mm a' : 'yyyy/MM/dd'}
        // mask={props?.mode == 'time' ? '__:__ _M' : '___/__/__'}
      />
    </LocalizationProvider>
  )
}

export default CustomDatePicker
