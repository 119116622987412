import { useEffect, useState } from 'react'

// material-ui
import { useTheme, styled } from '@mui/material/styles'
import {
  Badge,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Popper,
  TextField,
  Typography
} from '@mui/material'

import { shouldForwardProp } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { fetchListings, selectListing } from 'store/actions/listingActions'
import {
  IconUser,
  IconDashboard,
  IconDetails,
  IconCalendarTime,
  IconMessage,
  IconBell
} from '@tabler/icons'
import { getBookingScheme } from 'store/actions/bookingActions'
import useAuth from 'hooks/useAuth'

import favicon from 'assets/images/faviconPlain.png'
import faviconNotification from 'assets/images/faviconAlert.png'

// constant
const icons = {
  IconUser,
  IconDashboard,
  IconDetails,
  IconCalendarTime
}

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: '99%',
  top: '-55px !important',
  padding: '0 12px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px'
  }
}))

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
      background: 'transparent !important',
      paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
      width: 250
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 4,
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
    }
  })
)

// ==============================|| select INPUT ||============================== //

const ListSelectSection = () => {
  const theme = useTheme()

  const dispatch = useDispatch()

  const { hasPermission } = useAuth()

  const listings = useSelector(({ listingsStore }) => listingsStore.listings)
  const chatCount = useSelector(({ listingsStore }) => listingsStore.chatCount)
  const notificationCount = useSelector(
    ({ listingsStore }) => listingsStore.notificationCount
  )

  const selectedListing = useSelector(
    ({ listingsStore }) => listingsStore.selectedListing
  )
  const [hasMessage, setHasMessage] = useState(false)
  const [hasNotification, setHasNotification] = useState(false)

  const [listing, setListing] = useState({})
  const [reload, setReload] = useState(false)

  useEffect(() => {
    if (listings.length < 1) dispatch(fetchListings())
    setInterval(() => refreshListing(), 1000 * 60 * 60)
    return () => {}
  }, [])

  useEffect(() => {
    const hasChatAndCanAccessChat =
      (hasMessage || chatCount > 0) &&
      (hasPermission('chat', 'read') || hasPermission('chat', 'write'))
    const hasNotificationAndCanAccessNotification =
      (hasNotification || notificationCount > 0) &&
      (hasPermission('notifications', 'read') ||
        hasPermission('notifications', 'write'))

    if (
      (hasChatAndCanAccessChat || hasNotificationAndCanAccessNotification) &&
      selectedListing
    ) {
      document.getElementById('favicon').href = faviconNotification
    } else {
      document.getElementById('favicon').href = favicon
    }
  }, [chatCount, notificationCount, hasMessage, hasNotification])

  const checkInfo = () => {
    const other = listings?.filter(l => l.id != selectedListing?.id)
    const messages = other?.filter(li => li.chats > 0)
    const notifications = other?.filter(li => li.notifications > 0)
    setHasMessage(messages?.length > 0)
    setHasNotification(notifications?.length > 0)
  }

  useEffect(() => {
    checkInfo()
    if (selectedListing?.id)
      dispatch(
        getBookingScheme({
          listing_id: selectedListing?.id,
          listing_type: selectedListing?.listing_type,
          listing_title: selectedListing?.title
        })
      )
  }, [
    reload,
    selectedListing?.id
    // selectedListing?.title,
    // selectedListing?.listing_type,
    // listings
  ])

  const refreshListing = async () => {
    await dispatch(fetchListings())
    setTimeout(() => {
      setReload(prev => !prev)
    }, 1000 * 3)
  }

  useEffect(() => {
    if (listings?.length > 0) {
      let savedListing = localStorage.getItem('selectedListing')
      if (savedListing) {
        savedListing = JSON.parse(savedListing)
        const fresh = listings.find(
          lst =>
            lst.title == savedListing.title &&
            lst.listing_type == savedListing.listing_type
        )
        savedListing = fresh ? fresh : null
      }
      let toSelect = savedListing ? savedListing : listings[0]
      if (toSelect) {
        setListing(toSelect)
        dispatch(selectListing(toSelect))
        checkInfo()
      }
    }
  }, [listings])

  const handleChange = async event => {
    if (event?.target.value) {
      const list = event.target.value
      setListing(list)
      dispatch(selectListing(list))
    }

    await dispatch(fetchListings())
  }

  return (
    <>
      <FormControl
        style={{
          width: '30%',
          minWidth: '120px',
          marginLeft: '20px'
        }}
        className='listing-select'
      >
        <Grid sx={{ alignItems: 'center', display: 'flex', width: '100%' }}>
          <Grid
            sx={{
              alignItems: 'center',
              display: 'flex',
              position: 'absolute',
              left: 0,
              zIndex: 20,
              marginLeft: '10px'
            }}
          >
            {(hasPermission('chat', 'read') ||
              hasPermission('chat', 'write')) &&
              hasMessage && (
                <Badge
                  overlap='circular'
                  color='error'
                  variant='dot'
                  style={{ marginRight: 5 }}
                >
                  <IconMessage size={15} color={theme.palette.primary.dark} />
                </Badge>
              )}
            {(hasPermission('notifications', 'read') ||
              hasPermission('notifications', 'write')) &&
              hasNotification && (
                <Badge
                  overlap='circular'
                  color='error'
                  variant='dot'
                  style={{ marginRight: 5 }}
                >
                  <IconBell size={15} color={theme.palette.primary.dark} />
                </Badge>
              )}
          </Grid>
          <TextField
            select
            label={'Select a listing'}
            value={listing}
            onChange={handleChange}
            fullWidth
            InputProps={{ style: { paddingRight: 30 } }}
          >
            {listings?.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                sx={{
                  p: 1.5,
                  // maxWidth: '400px',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal'
                }}
              >
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <div style={{ width: '40px', minWidth: '40px' }}>
                    {item?.id != selectedListing?.id && (
                      <>
                        {(hasPermission('chat', 'read') ||
                          hasPermission('chat', 'write')) &&
                          item.chats > 0 && (
                            <Badge
                              overlap='circular'
                              color='error'
                              variant='dot'
                              style={{ marginRight: 5 }}
                            >
                              <IconMessage
                                size={15}
                                color={theme.palette.primary.dark}
                              />
                            </Badge>
                          )}
                        {(hasPermission('notifications', 'read') ||
                          hasPermission('notifications', 'write')) &&
                          item.notifications > 0 && (
                            <Badge
                              overlap='circular'
                              color='error'
                              variant='dot'
                              style={{ marginRight: 5 }}
                            >
                              <IconBell
                                size={15}
                                color={theme.palette.primary.dark}
                              />
                            </Badge>
                          )}
                      </>
                    )}
                  </div>
                  <div>{item.title}</div>
                </div>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </FormControl>
    </>
  )
}

export default ListSelectSection
