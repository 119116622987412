import PropTypes from 'prop-types'
import { forwardRef, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Badge,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery
} from '@mui/material'

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions'

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { capitalize } from 'store/actions/bookingActions'
import { soundFiles } from 'store/constant'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

let calculateChatCount = -1
const audio = new Audio()
audio.loop = false

const NavItem = ({ item, level }) => {
  const location = useLocation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const customization = useSelector(state => state.customization)
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))
  const { selectedListing, chatCount } = useSelector(
    ({ listingsStore }) => listingsStore
  )

  const bookingSchemes = useSelector(
    ({ bookingStore }) => bookingStore.bookingSchemes
  )

  const Icon = item.icon
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size='1.3rem' />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width:
          customization.isOpen.findIndex(id => id === item?.id) > -1 ? 8 : 6,
        height:
          customization.isOpen.findIndex(id => id === item?.id) > -1 ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  )

  let itemTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    ))
  }
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget }
  }

  const itemHandler = id => {
    dispatch({ type: MENU_OPEN, id })
    if (matchesSM) dispatch({ type: SET_MENU, opened: false })
  }

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex(id => id === item.id)
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id })
    }
  }, [])

  useEffect(() => {
    // console.log({ calculateChatCount, chatCount })
    if (item.id == 'chats' && (chatCount || chatCount == 0)) {
      let soundEnabled = localStorage.getItem('chatSoundEnabled')
      if (
        soundEnabled &&
        calculateChatCount > -1 &&
        chatCount > calculateChatCount
      ) {
        audio.src = soundFiles[soundEnabled] ?? soundFiles['Message']
        audio.play()
      }
      calculateChatCount = chatCount
    }
  }, [chatCount])

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`
      }}
      //   selected={customization.isOpen.findIndex(id => id === item.id) > -1}
      selected={item.url == location.pathname}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
        <Badge
          overlap='circular'
          color='error'
          badgeContent={
            item.id == 'chats' ? (chatCount > -1 ? chatCount : 0) : null
          }
          // variant='dot'
        >
          {itemIcon}
        </Badge>
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={
              customization.isOpen.findIndex(id => id === item.id) > -1
                ? 'h5'
                : 'body1'
            }
            color='inherit'
          >
            {item.id == 'timeSlots' ? (
              selectedListing?.listing_type == 'place' ? (
                'Opening hours'
              ) : selectedListing?.listing_type == 'activity' ? (
                'Schedule'
              ) : selectedListing?.listing_type == 'event' ? (
                'Calendar'
              ) : (
                'Time slots'
              )
            ) : item.id == 'bookingResources' ? (
              <>{capitalize(bookingSchemes?.resource_pl)}</>
            ) : item.id == 'bookingServices' ? (
              <>{capitalize(bookingSchemes?.service_pl)}</>
            ) : (
              <>{item.title}</>
            )}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant='caption'
              sx={{ ...theme.typography.subMenuCaption }}
              display='block'
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
}

export default NavItem
