import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/Loadable'
import AuthAndTermsGuard from 'utils/route-guard/AuthAndTermsGuard'

const ListingSelection = Loadable(lazy(() => import('views/ListingSelection')))

// ==============================|| MAIN ROUTING ||============================== //

const OtherRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <AuthAndTermsGuard>
        <ListingSelection />
      </AuthAndTermsGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/listings',
      element: <ListingSelection />
    }
  ]
}

export default OtherRoutes
