import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import BookingReports from 'views/BookingReports'
import AuthAndTermsGuard from 'utils/route-guard/AuthAndTermsGuard'
import useAuth from 'hooks/useAuth'
import { Typography } from '@mui/material'
import { Navigate } from 'react-router-dom'
// import Dashboard from 'views/Dashboard'
// import Details from 'views/Details'
// import Slots from 'views/Slots'

const Slots = Loadable(lazy(() => import('views/TimeSlots')))
const Dashboard = Loadable(lazy(() => import('views/Dashboard')))
const Details = Loadable(lazy(() => import('views/Details')))
const AppChat = Loadable(lazy(() => import('views/Chat')))
const Resources = Loadable(lazy(() => import('views/BookingResources')))
const Booking = Loadable(lazy(() => import('views/Bookings')))
const BookingSlots = Loadable(lazy(() => import('views/BookingSlots')))
const BookingServices = Loadable(lazy(() => import('views/BookingServices')))
const NoBookingSlots = Loadable(lazy(() => import('views/NoBookingSlots')))
const Settings = Loadable(lazy(() => import('views/Settings')))
const BookingDiscounts = Loadable(lazy(() => import('views/BookingDiscounts')))
const Submissions = Loadable(lazy(() => import('views/Submissions')))
const Home = Loadable(lazy(() => import('views/Home')))

const AppUserAccountProfile = Loadable(
  lazy(() => import('views/Users/account-profile'))
)

// ==============================|| MAIN ROUTING ||============================== //

// make this a function so that we can make use of the auth hook to check associates and permissions

const MainRoutes = () => {
  const { hasPermission, user } = useAuth()
  return {
    // path: '/',
    element: (
      <AuthAndTermsGuard>
        <MainLayout />
      </AuthAndTermsGuard>
    ),
    children: [
      {
        path: '/home',
        element: <Home />
      },
      {
        path: '/dashboard',
        //check is MERCHANT or has PERMISSION
        //cant see dashboard show something else here.
        element:
          hasPermission('dashboard', 'read') ||
          hasPermission('dashboard', 'write') ? (
            <Dashboard />
          ) : (
            <Navigate to={'/home'} replace />
          )
      },
      //check is MERCHANT or has PERMISSION
      hasPermission('details', 'read') || hasPermission('details', 'write')
        ? {
            path: '/details',
            element: <Details />
          }
        : {},
      hasPermission('timeslots', 'read') || hasPermission('timeslots', 'write')
        ? {
            path: '/time-slots',
            element: <Slots />
          }
        : {},
      {
        path: '/account',
        element: <AppUserAccountProfile />
      },
      user?.credit_balance != null &&
      (hasPermission('chat', 'read') || hasPermission('chat', 'write'))
        ? {
            path: '/chats',
            element: <AppChat />
          }
        : {},
      user?.credit_balance != null &&
      (hasPermission('bookings', 'read') || hasPermission('bookings', 'write'))
        ? {
            path: '/bookings',
            element: <Booking />
          }
        : {},
      ...(user?.credit_balance != null &&
      (hasPermission('booking-setup', 'read') ||
        hasPermission('booking-setup', 'write'))
        ? [
            {
              path: '/booking-slots',
              element: <BookingSlots />
            },
            {
              path: '/booking-rcs',
              element: <Resources />
            },
            {
              path: '/booking-scs',
              element: <BookingServices />
            },
            {
              path: '/no-booking-slots',
              element: <NoBookingSlots />
            },
            {
              path: '/booking-discounts',
              element: <BookingDiscounts />
            }
          ]
        : []),
      user?.credit_balance != null &&
      (hasPermission('reports', 'read') || hasPermission('reports', 'write'))
        ? {
            path: '/booking-reports',
            element: <BookingReports />
          }
        : {},
      user?.credit_balance != null &&
      (hasPermission('booking-settings', 'read') ||
        hasPermission('booking-settings', 'write') ||
        hasPermission('chat-settings', 'read') ||
        hasPermission('chat-settings', 'write'))
        ? {
            path: '/settings',
            element: <Settings />
          }
        : {},

      {
        path: '/submissions',
        element: <Submissions />
      }

      // {
      //   path: '*',
      //   // A 404 page is needed
      //   element: <Navigate to={'/home'} replace />
      // }
    ]
  }
}

export default MainRoutes
