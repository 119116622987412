import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'

// assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { padding } from '@mui/system'

// ==============================|| ACCORDION ||============================== //

const Accordion = ({
  data,
  defaultExpandedId = null,
  expandIcon,
  square,
  toggle
}) => {
  const theme = useTheme()

  const [expanded, setExpanded] = useState(null)
  const handleChange = panel => (event, newExpanded) => {
    if (toggle) setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    setExpanded(defaultExpandedId)
  }, [defaultExpandedId])

  return (
    <Box sx={{ width: '100%' }}>
      {data &&
        data.map(item => (
          <MuiAccordion
            key={item.id}
            defaultExpanded={!item.disabled && item.defaultExpand}
            expanded={
              (!toggle && !item.disabled && item.expanded) ||
              (toggle && expanded === item.id)
            }
            disabled={item.disabled}
            square={square}
            onChange={handleChange(item.id)}
          >
            <MuiAccordionSummary
              expandIcon={
                expandIcon || expandIcon === false ? (
                  expandIcon
                ) : (
                  <ExpandMoreIcon />
                )
              }
              sx={{
                color: theme.palette.mode === 'dark' ? 'grey.500' : 'grey.800',
                fontWeight: 500,
                padding: '1px'
                // marginBottom: '-20px'
              }}
            >
              {item.title}
            </MuiAccordionSummary>
            <MuiAccordionDetails sx={{ padding: '1px' }}>
              {item.content}
            </MuiAccordionDetails>
          </MuiAccordion>
        ))}
    </Box>
  )
}

Accordion.propTypes = {
  data: PropTypes.array,
  defaultExpandedId: PropTypes.string,
  expandIcon: PropTypes.object,
  square: PropTypes.bool,
  toggle: PropTypes.bool
}

export default Accordion
