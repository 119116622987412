// action - state management
import * as actionTypes from '../actions'

const initialState = {
  listings: [],
  selectedListing: null,
  selectedListingDetails: null,
  chatCount: -1,
  notificationCount: -1
}

const listingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LISTINGS:
      return { ...state, listings: action.payload }
    case actionTypes.SELECT_LISTING:
      const selelected = state.listings.find(
        list => list.id == action.listing_id
      )
      return {
        ...state,
        selectedListing: selelected
      }
    case actionTypes.UPDATE_CHAT_COUNT:
      return { ...state, chatCount: action.count }
    case actionTypes.UPDATE_NOTIFICATION_COUNT:
      return { ...state, notificationCount: action.count }
    case actionTypes.GET_LISTING_DETAILS:
      return { ...state, selectedListingDetails: action.details }
    case actionTypes.UPDATE_LISTING_DETAILS:
      return {
        ...state,
        errorMessage: null,
        selectedListingDetails: action.details
      }

    case actionTypes.CLEAR_LISTINGS: {
      return initialState
    }

    default:
      return state
  }
}

export default listingReducer
