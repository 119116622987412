/**
 * axios setup to use mock service
 */

import axios from 'axios'
import axiosRetry from 'axios-retry'
import { throttleFunction } from 'store/actions/helperFns'
import API from '../utils/constants/API'

const axiosServices = axios.create({
  baseURL: API.BASE_URL,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

export const globalRetriesCount = 2
export let networkError = false

axiosRetry(axiosServices, {
  retries: 0,
  retryDelay: () => 1000 * 10,
  retryCondition: err => {
    if (err?.response?.status == 401 && err?.response?.data?.code == 0) {
      return false
    } else {
      return true
    }
  }
})

// interceptor for http

axiosServices.interceptors.response.use(
  response => {
    // if (response.data.code == 0) {
    //   localStorage.removeItem('authenticated')
    //   window.location.href = '/login'
    // }
    return response
  },
  error => {
    if (error?.message == 'Network Error') {
      throttleFunction(() => {
        networkError = true
        // console.log(
        //   'Oops an error occured, please check your internet connection',
        //   error.message
        // )
        setTimeout(() => {
          networkError = false
        }, 1000 * 5)
      }, 500)
    } else {
      networkError = false
    }

    if (error?.response?.status == 401 && error?.response?.data?.code == 0) {
      localStorage.removeItem('authenticated')
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

export default axiosServices
