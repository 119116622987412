import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// reducer import
import customizationReducer from './reducers/customizationReducer'
import snackbarReducer from './reducers/snackbarReducer'
import cartReducer from './reducers/cartReducer'
import kanbanReducer from './reducers/kanbanReducer'
import listingReducer from './reducers/listingReducer'
import { slotsReducer } from './reducers/slotsReducer'
import { bookingReducer } from './reducers/bookingReducer'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'meshaina-'
    },
    cartReducer
  ),
  kanban: kanbanReducer,
  listingsStore: listingReducer,
  slotsStore: slotsReducer,
  bookingStore: bookingReducer
})

export default reducer
