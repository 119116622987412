import extras from './extras'
import merchant from './merchant'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [merchant, extras]
}

export default menuItems
