import Start from '../assets/audio/start.mp3'
import Interface from '../assets/audio/interface.mp3'
import Ding from '../assets/audio/ding.mp3'
import Bell from '../assets/audio/bell.mp3'
import Message from '../assets/audio/message.mp3'

// theme constant
export const gridSpacing = 3
export const drawerWidth = 260
export const appDrawerWidth = 320

// sounds
export const soundFiles = {
  Start,
  Interface,
  Ding,
  Bell,
  Message
}

//server offset
export const serverOffset = 3 * 60