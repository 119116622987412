import { forwardRef, useState } from 'react'
import PDF from 'react-pdf-js'
// material-ui
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography
} from '@mui/material'

// assets
import CloseIcon from '@mui/icons-material/Close'
import API from 'utils/constants/API'
import Loader from 'ui-component/Loader'
import { useTheme } from '@mui/material/styles'
import { pdfjs } from 'react-pdf'
import {
  IconArrowDownCircle,
  IconArrowUpCircle,
  IconCircleMinus,
  IconCirclePlus
} from '@tabler/icons'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
// slide animation
const Transition = forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
))

// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

export default function TermsDialog (props) {
  const [file, setFile] = useState(API.BASE_URL + API.TERMS)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setloading] = useState(true)
  const [scale, setScale] = useState(0.9)
  function onDocumentLoadSuccess (pages) {
    if (pages) setloading(false)
    setNumPages(pages)
  }
  const theme = useTheme()
  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={props.onClose}
        TransitionComponent={Transition}
      >
        {loading && <Loader />}

        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={props.onClose}
              aria-label='close'
              size='large'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h3' color='inherit' sx={{ ml: 2, flex: 1 }}>
              {props.title ?? 'Terms'}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          style={{
            minHeight: '40px',
            background: theme.palette.background.paper
          }}
          display={'flex'}
          justifyContent={'center'}
          alignItems='center'
        >
          <IconArrowUpCircle
            color={
              pageNumber > 1
                ? theme.palette.primary.main
                : theme.palette.grey[400]
            }
            onClick={() => {
              setPageNumber(prev => (prev > 1 ? prev - 1 : prev))
            }}
          />
          <span style={{ marginRight: '5px', marginLeft: '5px' }}>
            {pageNumber}/{numPages}
          </span>
          <IconArrowDownCircle
            color={
              pageNumber < numPages
                ? theme.palette.primary.main
                : theme.palette.grey[400]
            }
            style={{ marginRight: '30px' }}
            onClick={() => {
              setPageNumber(prev => (prev < numPages ? prev + 1 : prev))
            }}
          />

          <IconCircleMinus
            color={theme.palette.primary.main}
            style={{ marginRight: '8px' }}
            onClick={() => setScale(prev => (prev > 0.5 ? prev - 0.1 : prev))}
          />
          <IconCirclePlus
            color={theme.palette.primary.main}
            onClick={() => setScale(prev => prev + 0.1)}
          />
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <Document
              file={{
                url: file,
                data: file,
                withCredentials: true
              }}
              // options={{ withCredentials: true }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document> */}

          <Grid style={{ height: '85vh', overflowY: 'scroll' }}>
            <PDF
              scale={scale}
              withCredentials={true}
              file={file}
              page={pageNumber}
              onDocumentComplete={onDocumentLoadSuccess}
            />
          </Grid>
        </div>
      </Dialog>
    </div>
  )
}
