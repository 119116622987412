import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

// project imports
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import moment from 'moment'

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthAndTermsGuard = ({ children }) => {
  const { isLoggedIn } = useAuth()
  const [latest_terms_at, setlatest_terms_at] = useState(
    moment('Nov 18, 2022 00:00')
  )
  const navigate = useNavigate()
  const agreed_to_terms_at = localStorage.getItem('agreed_to_terms_at')

  useEffect(() => {
    if (
      !agreed_to_terms_at ||
      latest_terms_at > moment(agreed_to_terms_at) ||
      !isLoggedIn
    ) {
      navigate('terms', { replace: true })
    }
  }, [isLoggedIn, navigate])

  return children
}

AuthAndTermsGuard.propTypes = {
  children: PropTypes.node
}

export default AuthAndTermsGuard
