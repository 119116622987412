import { useRoutes } from 'react-router-dom'

// routes
import MainRoutes from './MainRoutes'
import LoginRoutes from './LoginRoutes'
import AuthenticationRoutes from './AuthenticationRoutes'
import OtherRoutes from './OtherRoutes'
import TermsRoutes from './TermsRoutes'
import _404Route from './_404Route'

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes () {
  return useRoutes([
    MainRoutes(),
    LoginRoutes,
    AuthenticationRoutes,
    OtherRoutes,
    TermsRoutes,
    _404Route
  ])
}
