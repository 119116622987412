let timerId = null
export const throttleFunction = function (func, delay) {
  if (timerId) {
    return
  }

  timerId = setTimeout(function () {
    func()
    timerId = undefined
  }, delay)
}

let debouncetimerId = null

export const debounceFunction = function (func, delay) {
  // Cancels the setTimeout method execution
  clearTimeout(debouncetimerId)

  // Executes the func after delay time.
  debouncetimerId = setTimeout(func, delay)
}

export const formatMoney = (num, dec = 0) => {
  var p = num.toFixed(dec).split('.')
  return (
    p[0]
      .split('')
      .reverse()
      .reduce(function (acc, num, i, orig) {
        return num + (num != '-' && i && !(i % 3) ? ',' : '') + acc
      }, '') + (p[1] ? '.' + p[1] : '')
  )
}

export const axiosWithRetry = axiosCall => {
  // starting with retries as 0
  // make axios call
  // incase of error
  // if(retries is less than 3) retry calling the api again  and increment retries
  // else reject api with the error

  let retries = 0
  return new Promise(async (resolve, reject) => {
    const makeCall = async () => {
      try {
        const response = await axiosCall
        resolve(response)
      } catch (err) {
        if (retries < 3) {
          setTimeout(async () => {
            retries++
            await makeCall()
          }, 3000)
        } else {
          // console.log('MAX attempts reached', retries)
          reject(err)
        }
      }
    }

    await makeCall()
  })
}
