import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/Loadable'
import AuthGuard from 'utils/route-guard/AuthGuard'

const TermsPage = Loadable(
  lazy(() => import('views/ListingSelection/TermsPage'))
)

// ==============================|| MAIN ROUTING ||============================== //

const TermsRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <AuthGuard>
        <TermsPage />
      </AuthGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/terms',
      element: <TermsPage />
    }
  ]
}

export default TermsRoutes
