import {
  GET_LISTINGS,
  GET_LISTING_DETAILS,
  SELECT_LISTING,
  UPDATE_CHAT_COUNT,
  UPDATE_LISTING_DETAILS,
  UPDATE_NOTIFICATION_COUNT
} from 'store/actions'
import axios, { globalRetriesCount } from 'utils/axios'
import API from 'utils/constants/API'

export const fetchListings = () => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.GET_LISTINGS, null, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })

      if (data.code == 200) {
        dispatch({ type: GET_LISTINGS, payload: data.data })
      }
    } catch (err) {}
  }
}

export const selectListing = listing => {
  if (!listing) return async dispatch => {}
  const { id: listing_id, listing_type } = listing
  return async dispatch => {
    dispatch({ type: SELECT_LISTING, listing_id })
    localStorage.setItem('selectedListing', JSON.stringify(listing))
  }
}

export const getListingDetails = (listing_id, listing_type) => {
  if (!listing_id) return async dispatch => {}
  return async dispatch => {
    try {
      const { data } = await axios.post(
        API.GET_LISTING_DETAILS,
        {
          listing_id,
          listing_type
        },
        {
          'axios-retry': {
            retries: globalRetriesCount
          }
        }
      )
      const details = { ...data.data, listing_id, listing_type }

      dispatch({ type: GET_LISTING_DETAILS, details })
    } catch (err) {}
  }
}

export const updateListingDetails = payload => {
  return async dispatch => {
    try {
      const { data } = await axios.post(API.UPDATE_LISTING_DETAILS, payload)

      if (data.code != 200) {
        throw { message: data?.message }
      }
      const details = {}
      for (var pair of payload.entries()) {
        details[pair[0]] = pair[1]
      }

      delete details.delete_file

      dispatch({
        type: UPDATE_LISTING_DETAILS,
        listing_id: payload.listing_id,
        details
      })
    } catch (err) {
      throw err
    }
  }
}

export const getChatCount = payload => {
  return async dispatch => {
    try {
      const { data: response } = await axios.post(API.CHAT_STATUS, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })
      if (response.code == 200) {
        dispatch({
          type: UPDATE_CHAT_COUNT,
          count: response?.data?.count
        })
      } else {
        dispatch({
          type: UPDATE_CHAT_COUNT,
          count: 0
        })
      }
    } catch (e) {}
  }
}

export const updateNotificationCount = count => {
  return async dispatch => {
    dispatch({
      type: UPDATE_NOTIFICATION_COUNT,
      count
    })
  }
}
