const api_base = process.env.REACT_APP_API_BASE
const API = {
  dateFormat: 'MMMM DD, YYYY',
  BASE_URL: process.env.REACT_APP_BASE_URL,

  // auth
  LOGIN: api_base + '/login',
  LOGOUT: api_base + '/logout',
  ACCOUNT: api_base + '/account',
  ACCOUNT_UPDATE: api_base + '/account-update',
  PASSWORD_UPDATE: api_base + '/password-update',

  ADD_ASSOCIATE: api_base + '/associates-update',
  GET_ASSOCIATE: api_base + '/associates-read',

  // listing
  GET_LISTINGS: api_base + '/listings',
  GET_LISTING_DETAILS: api_base + '/details',
  UPDATE_LISTING_DETAILS: api_base + '/details-update',
  GET_PLACE_TIME_SLOTS: api_base + '/place-time-slots-read',
  UPDATE_PLACE_TIME_SLOTS: api_base + '/place-time-slots-update',
  GET_ACTIVITY_TIME_SLOTS: api_base + '/activity-time-slots-read',
  UPDATE_ACTIVITY_TIME_SLOTS: api_base + '/activity-time-slots-update',
  GET_EVENT_TIME_SLOTS: api_base + '/event-time-slots-read',
  UPDATE_EVENT_TIME_SLOTS: api_base + '/event-time-slots-update',

  //Chat
  CONVERSATION_LIST: api_base + '/chat-conversation-list',
  LISTING_CHATS: api_base + '/chat-conversation-messages',
  NEW_CHAT: api_base + '/new-chat-message',
  CHAT_STATUS: api_base + '/chat-status',

  //Booking
  GET_BOOKING_RESOURCES: api_base + '/booking-resources-read',
  UPDATE_BOOKING_RESOURCES: api_base + '/booking-resources-update',
  UPDATE_BOOKING_RESOURCE_SERVICE_PHOTO: api_base + '/booking-resources-services-image-update',
  UPDATE_BOOKING_RESOURCES_LAYOUT: api_base + '/booking-resource-layout-update',

  GET_BOOKING_SERVICES: api_base + '/booking-services-read',
  UPDATE_BOOKING_SERVICES: api_base + '/booking-services-update',

  GET_BOOKING_SLOTS: api_base + '/booking-slots-read',
  UPDATE_BOOKING_SLOTS: api_base + '/booking-slots-update',
  BOOKING_SLOTS_FORM: api_base + '/booking-slots-form',

  GET_NOBOOKING_SLOTS: api_base + '/nobooking-slots-read',
  UPDATE_NOBOOKING_SLOTS: api_base + '/nobooking-slots-update',
  GET_NO_BOOKING_FORM: api_base + '/nobooking-slots-form',

  GET_BOOKINGS: api_base + '/bookings-read',
  UPDATE_BOOKINGS: api_base + '/bookings-update',
  GET_BOOKING_FORM: api_base + '/bookings-form',
  GET_BOOKED_UNITS: api_base + '/booked-units-read',
  GET_RESOURCES_BOOKED_UNITS: api_base + '/resource-booked-units',
  BOOKED_UNIT_STATUS_UPDATE: api_base + '/booked-unit-status-update',

  GET_BOOKINGS_REPORT: api_base + '/bookings-report',
  GET_BOOKED_UNITS_REPORT: api_base + '/booked-units-report',
  GET_CUSTOM_REPORT: api_base + '/booked-units-grouping-report',

  GET_BOOKING_REVENUE: api_base + '/dashboard-booking-revenue',
  GET_BOOKING_COUNT: api_base + '/dashboard-bookings-count',
  GET_BOOKED_UNITS_COUNT: api_base + '/dashboard-booked-units-count',
  GET_BOOKED_RESOURCES: api_base + '/dashboard-booked-resources',
  GET_BOOKING_FEEDBACK: api_base + '/dashboard-booking-feedback',

  GET_BOOKED_SERVICES: api_base + '/dashboard-booked-services',
  GET_PHONE_SUGGESTION: api_base + '/booking-info-search',

  //Notifictaions
  NOTIFICATIONS: api_base + '/notifications',
  NOTIFICATIONS_UPDATE: api_base + '/notifications-update',
  NOTIFICATIONS_COUNT: api_base + '/notifications-count',

  //Discounts
  GET_BOOKING_DISCOUNTS: api_base + '/booking-discounts-read',
  UPDATE_BOOKING_DISCOUNTS: api_base + '/booking-discounts-update',
  BOOKING_DISCOUNTS_FORM: api_base + '/booking-discounts-form',

  //Settings
  UPDATE_SETTINGS: api_base + '/merchant-settings-update',
  READ_SETTINGS: api_base + '/merchant-settings-read',
  TERMS: api_base + '/public-files/terms',

  //SCHEME
  READ_BOOKING_SCHEME: api_base + '/listing-booking-scheme',
  VALIDATE_PHONE: api_base + '/phone-validation',

  //CREDITS
  CREDITS_HISTORY: api_base + '/credits-history',
  CREDITS_BOOST: api_base + '/credits-boost',
  INVOICE_SYNC: api_base + '/invoice-sync',

  //PAYOUTS
  PAYOUT_BALANCE: api_base + '/payout-balance',
  PAYOUT_HISTORY: api_base + '/payout-history',
  PAYOUT_INVOICES: api_base + '/payout-invoices',
  PAYOUT_BANK_DETAILS_UPDATE: api_base + '/payout-bank-details-update',
  PAYOUT_BANK_DETAILS_READ: api_base + '/payout-bank-details-read',

  FILE_UPLOAD:
    window.location.protocol + '//' + window.location.host + '/upload-files'
}

export default API

//http://gurutechnolabs.co.in/website/laravel/meshaina/
