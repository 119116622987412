// action - state management
import { ACCOUNT_FAILED, LOGIN, LOGOUT, REGISTER } from '../actions'

// ==============================|| ACCOUNT REDUCER ||============================== //

const accountReducer = (state, action) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload
      return {
        ...state,
        user
      }
    }
    case LOGIN: {
      const { user } = action.payload
      return {
        ...state,
        accountFailed: false,
        isLoggedIn: true,
        isInitialized: true,
        user
      }
    }
    case LOGOUT: {
      return {
        ...state,
        accountFailed: false,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      }
    }
    case ACCOUNT_FAILED: {
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        accountFailed: true
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default accountReducer
