// action - state management
import * as actionTypes from '../actions'

const initialState = {
  placeSlots: [],
  activitySlots: [],
  eventSlots: []
}

export const slotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PLACE_TIME_SLOTS:
      return { ...state, placeSlots: action.slots }
    case actionTypes.GET_ACTIVITY_TIME_SLOTS:
      return { ...state, activitySlots: action.slots }
    case actionTypes.GET_EVENT_TIME_SLOTS:
      return { ...state, eventSlots: action.slots }
    default:
      return state
  }
}
