import PropTypes from 'prop-types'
import { useState } from 'react'

// material-ui
import { styled } from '@mui/material/styles'
import { Dialog, Divider, IconButton } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

// assets
import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
  <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </DialogTitle>
)

BootstrapDialogTitle.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node
}

export default function FormDialog (props) {
  return (
    <div>
      <BootstrapDialog
        maxWidth={props.maxWidth ?? 'sm'}
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        className='form-dialog'
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={props.handleClose}
        >
          {props?.title}
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent>{props.children}</DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={props.handleSave}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  )
}
