import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  ButtonBase,
  Drawer,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { IconChecks, IconSettings } from '@tabler/icons'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'

// project imports
import SubCard from 'ui-component/cards/SubCard'
import AnimateButton from 'ui-component/extended/AnimateButton'
import {
  MENU_TYPE,
  PRESET_COLORS,
  SET_BORDER_RADIUS,
  SET_FONT_FAMILY,
  SET_OUTLINED_FILLED,
  THEME_RTL
} from 'store/actions'
import { gridSpacing, soundFiles } from 'store/constant'

// color import
import colors from 'assets/scss/_themes-vars.module.scss'
import theme1 from 'assets/scss/_theme1.module.scss'
import theme2 from 'assets/scss/_theme2.module.scss'
import theme3 from 'assets/scss/_theme3.module.scss'
import theme4 from 'assets/scss/_theme4.module.scss'
import theme5 from 'assets/scss/_theme5.module.scss'
import theme6 from 'assets/scss/_theme6.module.scss'
import useAuth from 'hooks/useAuth'
import { debounceFunction } from 'store/actions/helperFns'

// concat 'px'
function valueText (value) {
  return `${value}px`
}

const PresetColor = ({ color, presetColor, setPresetColor }) => (
  <Grid item>
    <ButtonBase
      sx={{ borderRadius: '12px' }}
      onClick={() => setPresetColor(color.id)}
    >
      <Avatar
        variant='rounded'
        color='inherit'
        sx={{
          background: `linear-gradient(135deg, ${color.primary} 50%, ${color.secondary} 50%)`,
          opacity: presetColor === color.id ? 0.6 : 1
        }}
      >
        {presetColor === color.id && <IconChecks color='#fff' />}
      </Avatar>
    </ButtonBase>
  </Grid>
)

PresetColor.propTypes = {
  color: PropTypes.shape({
    id: PropTypes.string,
    primary: PropTypes.string,
    secondary: PropTypes.string
  }),
  presetColor: PropTypes.string,
  setPresetColor: PropTypes.func
}

// ==============================|| LIVE CUSTOMIZATION ||============================== //
const audio = new Audio()
const Customization = () => {
  const theme = useTheme()
  const { hasPermission } = useAuth()
  const dispatch = useDispatch()
  const customization = useSelector(state => state.customization)
  const [selectedSound, setSelectedSound] = useState('Message')
  const [sounds, setSounds] = useState([
    'Start',
    'Interface',
    'Ding',
    'Bell',
    'Message'
  ])
  const [notificationSoundEnabled, setNotificationSoundEnabled] = useState(
    false
  )
  const [chatSoundEnabled, setChatSoundEnabled] = useState(null)
  useEffect(() => {
    let chat = localStorage.getItem('chatSoundEnabled')
    setChatSoundEnabled(
      chat ? (sounds.includes(chat) ? chat : 'Message') : null
    )

    let notif = localStorage.getItem('notificationSoundEnabled')
    setNotificationSoundEnabled(
      notif ? (sounds.includes(notif) ? notif : 'Start') : null
    )
  }, [])

  const handleChatSoundChange = e => {
    if (chatSoundEnabled) {
      localStorage.removeItem('chatSoundEnabled')
      setChatSoundEnabled(null)
    } else {
      localStorage.setItem('chatSoundEnabled', 'Message')
      setChatSoundEnabled('Message')
    }
  }
  const handleNotificationSoundChange = e => {
    if (notificationSoundEnabled) {
      localStorage.removeItem('notificationSoundEnabled')
      setNotificationSoundEnabled(null)
    } else {
      localStorage.setItem('notificationSoundEnabled', 'Start')
      setNotificationSoundEnabled('Start')
    }
  }

  // drawer on/off
  const [open, setOpen] = useState(false)
  const handleToggle = () => {
    setOpen(!open)
  }

  const savedMode = localStorage.getItem('mode')
  // state - layout type
  const [navType, setNavType] = useState(
    savedMode ? savedMode : customization.navType
  )

  useEffect(() => {
    if (open && chatSoundEnabled) {
      audio.src = soundFiles[chatSoundEnabled]
      debounceFunction(() => {
        audio.play()
      }, 800)
    }
  }, [chatSoundEnabled])

  useEffect(() => {
    if (open && notificationSoundEnabled) {
      audio.src = soundFiles[notificationSoundEnabled]
      debounceFunction(() => {
        audio.play()
      }, 800)
    }
  }, [notificationSoundEnabled])

  useEffect(() => {
    dispatch({ type: MENU_TYPE, navType })
  }, [dispatch, navType])

  const handleModeChange = e => {
    localStorage.setItem('mode', e.target.value)
    setNavType(e.target.value)
  }
  // state - preset color
  const [presetColor, setPresetColor] = useState(customization.presetColor)
  useEffect(() => {
    dispatch({ type: PRESET_COLORS, presetColor })
  }, [dispatch, presetColor])

  // state - border radius
  const [borderRadius, setBorderRadius] = useState(customization.borderRadius)
  const handleBorderRadius = (event, newValue) => {
    setBorderRadius(newValue)
  }

  useEffect(() => {
    dispatch({ type: SET_BORDER_RADIUS, borderRadius })
  }, [dispatch, borderRadius])

  // state - filled with outline textfield
  const [outlinedFilled, setOutlinedFilled] = useState(
    customization.outlinedFilled
  )
  const handleOutlinedFilled = event => {
    setOutlinedFilled(event.target.checked)
  }

  useEffect(() => {
    dispatch({ type: SET_OUTLINED_FILLED, outlinedFilled })
  }, [dispatch, outlinedFilled])

  // state - RTL layout
  const [rtlLayout, setRtlLayout] = useState(customization.rtlLayout)
  const handleRtlLayout = event => {
    setRtlLayout(event.target.checked)
  }

  if (customization.rtlLayout) {
    document?.querySelector('html')?.setAttribute('dir', 'rtl')
  } else {
    document?.querySelector('html')?.removeAttribute('dir')
  }

  useEffect(() => {
    dispatch({ type: THEME_RTL, rtlLayout })
  }, [dispatch, rtlLayout])

  let initialFont
  switch (customization.fontFamily) {
    case `'Inter', sans-serif`:
      initialFont = 'Inter'
      break
    case `'Poppins', sans-serif`:
      initialFont = 'Poppins'
      break
    case `SegoeUI`:
    default:
      initialFont = 'SegoeUI'
      break
  }

  // state - font family
  const [fontFamily, setFontFamily] = useState(initialFont)
  useEffect(() => {
    let newFont
    switch (fontFamily) {
      case 'Inter':
        newFont = `'Inter', sans-serif`
        break
      case 'Poppins':
        newFont = `'Poppins', sans-serif`
        break
      case 'SegoeUI':
      default:
        newFont = `SegoeUI`
        break
    }
    dispatch({ type: SET_FONT_FAMILY, fontFamily: newFont })
  }, [dispatch, fontFamily])

  const colorOptions = [
    {
      id: 'default',
      primary:
        theme.palette.mode === 'dark'
          ? colors.darkPrimaryMain
          : colors.primaryMain,
      secondary:
        theme.palette.mode === 'dark'
          ? colors.darkSecondaryMain
          : colors.secondaryMain
    },
    {
      id: 'theme1',
      primary:
        theme.palette.mode === 'dark'
          ? theme1.darkPrimaryMain
          : theme1.primaryMain,
      secondary:
        theme.palette.mode === 'dark'
          ? theme1.darkSecondaryMain
          : theme1.secondaryMain
    },
    {
      id: 'theme2',
      primary:
        theme.palette.mode === 'dark'
          ? theme2.darkPrimaryMain
          : theme2.primaryMain,
      secondary:
        theme.palette.mode === 'dark'
          ? theme2.darkSecondaryMain
          : theme2.secondaryMain
    },
    {
      id: 'theme3',
      primary:
        theme.palette.mode === 'dark'
          ? theme3.darkPrimaryMain
          : theme3.primaryMain,
      secondary:
        theme.palette.mode === 'dark'
          ? theme3.darkSecondaryMain
          : theme3.secondaryMain
    },
    {
      id: 'theme4',
      primary:
        theme.palette.mode === 'dark'
          ? theme4.darkPrimaryMain
          : theme4.primaryMain,
      secondary:
        theme.palette.mode === 'dark'
          ? theme4.darkSecondaryMain
          : theme4.secondaryMain
    },
    {
      id: 'theme5',
      primary:
        theme.palette.mode === 'dark'
          ? theme5.darkPrimaryMain
          : theme5.primaryMain,
      secondary:
        theme.palette.mode === 'dark'
          ? theme5.darkSecondaryMain
          : theme5.secondaryMain
    },
    {
      id: 'theme6',
      primary:
        theme.palette.mode === 'dark'
          ? theme6.darkPrimaryMain
          : theme6.primaryMain,
      secondary:
        theme.palette.mode === 'dark'
          ? theme6.darkSecondaryMain
          : theme6.secondaryMain
    }
  ]

  return (
    <>
      {/* toggle button */}
      <Tooltip title='Interactivity'>
        <Fab
          component='div'
          onClick={handleToggle}
          size='medium'
          variant='circular'
          color='secondary'
          sx={{
            borderRadius: 0,
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '4px',
            top: '25%',
            position: 'fixed',
            right: 10,
            zIndex: theme.zIndex.speedDial,
            boxShadow: theme.customShadows.secondary
          }}
        >
          <AnimateButton type='rotate'>
            <IconButton color='inherit' size='large' disableRipple>
              <IconSettings />
            </IconButton>
          </AnimateButton>
        </Fab>
      </Tooltip>

      <Drawer
        anchor='right'
        onClose={handleToggle}
        open={open}
        PaperProps={{
          className: 'adaptiveDrawer',
          sx: {
            width: 280
          }
        }}
      >
        <SubCard title='Interactivity'>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Theme</FormLabel>
            <RadioGroup
              row
              aria-label='layout'
              value={navType}
              onChange={handleModeChange}
              name='row-radio-buttons-group'
            >
              <FormControlLabel
                value='light'
                control={<Radio />}
                label='Light'
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 },
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.grey[900]
                  }
                }}
              />
              <FormControlLabel
                value='dark'
                control={<Radio />}
                label='Dark'
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 },
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.grey[900]
                  }
                }}
              />
            </RadioGroup>
          </FormControl>
          {(hasPermission('notifications', 'read') ||
            hasPermission('notifications', 'write')) && (
            <Grid>
              <FormControl component='fieldset' sx={{ mt: 2 }}>
                <FormLabel component='legend'>Notification sound</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={notificationSoundEnabled}
                      onChange={handleNotificationSoundChange}
                      inputProps={{ 'aria-label': 'controlled-direction' }}
                    />
                  }
                  label={notificationSoundEnabled ? 'On' : 'Off'}
                />
              </FormControl>
              {notificationSoundEnabled && (
                <TextField
                  select
                  label={'Select a sound'}
                  value={notificationSoundEnabled}
                  onChange={e => {
                    const val = e.target.value
                    localStorage.setItem('notificationSoundEnabled', val)
                    setNotificationSoundEnabled(val)
                  }}
                  sx={{ mt: 1 }}
                  fullWidth
                >
                  {sounds?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <Grid
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography>{item}</Typography>
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          )}
          {(hasPermission('chat', 'read') ||
            hasPermission('chat', 'write')) && (
            <Grid>
              <FormControl component='fieldset' sx={{ mt: 2 }}>
                <FormLabel component='legend'>Chat sound</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={chatSoundEnabled}
                      onChange={handleChatSoundChange}
                      inputProps={{ 'aria-label': 'controlled-direction' }}
                    />
                  }
                  label={chatSoundEnabled ? 'On' : 'Off'}
                />
              </FormControl>
              {chatSoundEnabled && (
                <TextField
                  select
                  label={'Select a sound'}
                  value={chatSoundEnabled}
                  onChange={e => {
                    const val = e.target.value
                    localStorage.setItem('chatSoundEnabled', val)
                    setChatSoundEnabled(val)
                  }}
                  sx={{ mt: 1 }}
                  fullWidth
                >
                  {sounds?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <Grid
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography>{item}</Typography>
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          )}
        </SubCard>
      </Drawer>
    </>
  )
}

export default Customization
